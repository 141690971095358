$theme-colors: (
	"primary": #c51014,
	"accent": #0067b6,
    "green": #00a650,
    "green-light": #99daa2,
    "brown": #b47731,
    "brown-light": #dcbf9d,
    "blue": #007bff,
    "blue-light": #007bff,
);

$font-family-sans-serif: MyriadPro, sans-serif;


//
// Buttons
//

$btn-font-weight: 600;
$btn-padding-x: 1.5rem;


//
// Custom
//

$custom-file-text: (
	sl: "Išči"
);


//
// Breadcrumbs
//

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-x: 0;

//
// Navbar.
//

$navbar-dark-color: rgba(255, 255, 255, 0.8);
