@font-face {
  font-family: MyriadPro;
  font-weight: 300;
  src: url("../../fonts/MyriadPro/MyriadPro-Light.otf") format('opentype');
}

@font-face {
  font-family: MyriadPro;
  font-weight: 400;
  src: url("../../fonts/MyriadPro/MyriadPro-Regular.otf") format('opentype');
}

@font-face {
  font-family: MyriadPro;
  font-weight: 600;
  src: url("../../fonts/MyriadPro/MyriadPro-Semibold.otf") format('opentype');
}

@font-face {
  font-family: MyriadPro;
  font-weight: 700;
  src: url("../../fonts/MyriadPro/MyriadPro-Bold.otf") format('opentype');
}

@font-face {
  font-family: MyriadPro;
  font-weight: 900;
  src: url("../../fonts/MyriadPro/MyriadPro-Black.otf") format('opentype');
}

@font-face {
  font-family: MinionPro;
  font-weight: 400;
  src: url("../../fonts/MinionPro/MinionPro-Regular.otf") format('opentype');
}

@font-face {
  font-family: MinionPro;
  font-weight: 500;
  src: url("../../fonts/MinionPro/MinionPro-Medium.otf") format('opentype');
}

@font-face {
  font-family: MinionPro;
  font-weight: 600;
  src: url("../../fonts/MinionPro/MinionPro-Semibold.otf") format('opentype');
}

@font-face {
  font-family: MinionPro;
  font-weight: 700;
  src: url("../../fonts/MinionPro/MinionPro-Bold.otf") format('opentype');
}
