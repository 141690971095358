.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.card-action {
    &:hover {
        background-color: theme-color("light");
        text-decoration: none;
    }
}

.book-item {
    &:hover .book-item-image-add-to-cart-overlay {
        transform: translateY(0%);
    }
}

.book-item-image-add-to-cart-overlay {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    padding: 15px 10px;
    color: #fff;
    background-color: theme-color("primary");
    text-align: center;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    transition: .4s ease;
}

.book-item-image-discount-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    left: 22px;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: theme-color("primary");
    text-align: center;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: .4s ease;
    z-index: 1;
}

.book-item-home-image-discount-overlay {
    @extend .book-item-image-discount-overlay;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
}

.book-item-image {
    max-height: 350px;

    @include media-breakpoint-up(md) {
        max-height: 200px;
    }
}

.book-item-cart-image {
    max-height: 300px;

    @include media-breakpoint-up(md) {
        max-height: 120px;
    }
}

.book-item-checkout-image {
    max-height: 200px;

    @include media-breakpoint-up(md) {
        max-height: 60px;
    }
}

.book-item-page-book-item-image {
    max-height: 350px;

    @include media-breakpoint-up(lg) {
        max-height: 500px;
    }
}

.font-family-minion-pro {
    font-family: MinionPro, MyriadPro, sans-serif !important;
}

.alert > ul {
    margin-bottom: 0;
    padding-left: 20px;
}

.hide-asterisk .asteriskField {
    display: none;
}

#div_id_delivery_by .form-check {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
}

#div_id_delivery_by .form-check-inline {
    display: block;
}

.p-condensed {
    p {
        margin-bottom: .25rem;
    }
}

.gutters-sm {
  margin-right: 7.5px;
  margin-left: 7.5px;

  > .col,
  > [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

table {
    @extend .table;
}

.StripeElement {
    @extend .form-control;
}

.StripeElement--focus {
    @extend .form-control:focus;
}

.StripeElement--invalid {
    @extend .is-invalid;
}

.StripeElement--complete {
    @extend .is-valid;
}

#card-errors {
	margin-top: 0.25rem;
	color: theme-color("danger");
}

//.StripeElement {
//  box-sizing: border-box;
//
//  height: 40px;
//
//  padding: 10px 12px;
//
//  border: 1px solid transparent;
//  border-radius: 4px;
//  background-color: white;
//
//  box-shadow: 0 1px 3px 0 #e6ebf1;
//  -webkit-transition: box-shadow 150ms ease;
//  transition: box-shadow 150ms ease;
//}
//
//.StripeElement--focus {
//  box-shadow: 0 1px 3px 0 #cfd7df;
//}
//
//.StripeElement--invalid {
//  border-color: #fa755a;
//}
//
//.StripeElement--webkit-autofill {
//  background-color: #fefde5 !important;
//}

.toast {
    flex-basis: auto !important;
}

.card-header-blue {
    background-color: #cce1f0;
}

.card-header-red {
    background-color: #f7b7b7;
}
